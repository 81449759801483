import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllNursesAPI } from "./nursesService";

export interface ExtensionDashboardState {
  loading: boolean;
  error: any;
  nursesList: any;
}

const initialState: ExtensionDashboardState = {
  loading: false,
  error: null,
  nursesList: [],
};

export const dashboardSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (
      state: ExtensionDashboardState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },

    updateErrorMessage: (
      state: ExtensionDashboardState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getAllNursesAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllNursesAPI.fulfilled,
      (state: ExtensionDashboardState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.nursesList = action.payload?.data;
      }
    );
    builder.addCase(
      getAllNursesAPI.rejected,
      (state: ExtensionDashboardState, action: any) => {
        state.loading = false;
      }
    );
  },
});

export const { setLoading, updateErrorMessage } = dashboardSlice.actions;

export default dashboardSlice.reducer;
