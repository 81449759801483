import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { HandleClickStyled } from "./HandleClick.styled";
import { bindActionCreators } from "redux";
import { Row, Col, Button, CardDeck, CardColumns } from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';
import facebook_fill from "../../img/facebook-box-fill.png";
import instagram_fill from "../../img/instagram-fill.png";
import twitter_fill from "../../img/twitter-x-fill.png";
import linkedin_fill from "../../img/linkedin-box-fill.png";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Link, withRouter } from "react-router-dom";
const Footer = (props: any) => {
  const dispatch = useDispatch();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const checkIn = query.get("checkIn");
  const checkOut = query.get("checkOut");
  const chanel = query.get("chanel");
  const city = query.get("city");

  return (
    <>
      <HandleClickStyled>
        <div className="extension-footer-container">
          Re directing to price line
        </div>
      </HandleClickStyled>
    </>
  );
};

export default Footer;
