import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Upload, Image, message } from "antd";
import { FiUpload } from "react-icons/fi";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ModalImageUploadStyled } from "./ModalImageUpload.styled";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getS3PresignedUrl } from "../../redux/slices/auth/authService";
import { Button as AntdButton } from "antd";
const ModalImageUpload = ({
  handleModalClose,
  handleUploadSuccess,
  modalShow = false,
}: any) => {
  const [previewImage, setPreviewImage] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const { user } = useSelector((reduxState: any) => reduxState.auth);
  const dispatch = useDispatch();

  const uploadImageToS3 = async (image: File) => {
    try {
      const presignBody = {
        id: `${user?.id}`,
        ext: ".png",
      };
      const presignedRes = await dispatch(getS3PresignedUrl(presignBody));
      const presignedUrlResp = JSON.parse(JSON.stringify(presignedRes));
      console.log("presignedUrlResp", presignedUrlResp);

      const presignedUrl = presignedUrlResp?.payload?.signedUrL?.signedUrL;
      const publicUrl = presignedUrlResp?.payload?.signedUrL?.publicUrl;

      await axios.put(presignedUrl, image, {
        headers: {
          "Content-Type": "image/png", // Ensure the correct content type is set
        },
      });
      console.log("Uploaded Image URL:", publicUrl);
      return publicUrl;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      throw error;
    }
  };

  const handleImgFileChange = async (info: any) => {
    const file = info.file.originFileObj;
    if (file) {
      try {
        const url = await uploadImageToS3(file);
        setImageUrl(url);
        handleUploadSuccess(url);
        handleModalClose();
      } catch (error) {
        message.error("Error uploading front image. Please try again.");
      }
    }
  };

  return (
    <ModalImageUploadStyled>
      <Modal
        show={modalShow}
        onHide={handleModalClose}
        centered
        className="modal-image-upload"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Upload
            accept="image/png"
            showUploadList={false}
            onChange={handleImgFileChange}
          >
            <AntdButton style={{ height: "100%" }} icon={<FiUpload />}>
              Upload Image
            </AntdButton>
          </Upload>
          {previewImage && (
            <div className="image-preview">
              <Image width={200} src={previewImage} alt="Uploaded Image" />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </ModalImageUploadStyled>
  );
};

export default ModalImageUpload;
