import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get, patch } from "../../../library/Requests/helpers";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (body: any) => await post(`${SERVER_IP}/api/v1/auth/login`, body)
);
export const changePasswordAPi = createAsyncThunk(
  "CHANGE_PASSWORD",
  async (body: any) => await post(`${SERVER_IP}/auth/changePassword`, body)
);
export const signUpUser = createAsyncThunk(
  "auth/signUpUser",
  async (body: any) => await post(`${SERVER_IP}/addStore`, body)
);

export const addNewUser = createAsyncThunk(
  "auth/addNewUser",
  async (body: any) => await post(`${SERVER_IP}/customer/create `, body)
);

export const getS3PresignedUrl = createAsyncThunk(
  "auth/getS3PresignedUrl",
  async (body: any) => await patch(`${SERVER_IP}/api/v1/config/presign`, body)
);
