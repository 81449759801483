import React, { useEffect, useState } from "react";
import { MyProfileInfoStyled } from "./MyProfileInfo.styled";
// import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../images/edit.svg";
import eyeIcon from "../../images/eyeClose.svg";

import EditProfile from "./EditProfile";
import { Modal } from "antd";
import { Form } from "react-bootstrap";
const MyProfileInfo = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [signUpData, setSignUpData] = useState({});
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showPassWordModel, setShowPassWordModel] = useState(false);
  const [showSuccessPass, setShowSuccessPass] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  // const { allProducts } = useSelector(({ productCart }) => productCart);
  useEffect(() => {
    console.log("vinod");
    getUserDetailsByIdD();
  }, []);
  useEffect(() => {
    updateUSerDetailstoState();
  }, [user]);

  const updateUSerDetailstoState = () => {
    console.log("user 333", user);
    const addr = { ...signUpData };
    addr.fullName = user?.fullName;
    addr.influenceCode = user?.influenceCode;
    addr.phoneNumber = user?.phoneNumber;
    addr.email = user?.email;
    setSignUpData(addr);
    // user
  };

  const handleChangeValue = (e) => {
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    if (name == "phoneNumber" || name == "officeNo") {
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        setSignUpData({
          ...addr,
          [name]: value1,
        });
      }
    } else {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
    }
  };
  const getUserDetailsByIdD = () => {
    // dispatch(getUserDetailsById({ id: user?._id }));
  };
  const handleLogout = () => {
    localStorage.clear();
    history.push("/signIn");
    window.location.reload();
  };
  const handleEdit = () => {
    setIsEdit(true);
  };
  const handleCancelEdit = () => {
    setIsEdit(false);
  };
  const handleUpdateEdit = () => {
    if (signUpData?.fullName && signUpData?.phoneNumber) {
      // setIsEdit(true);
      setShowWarningMessage(true);
    }
  };
  const handleUpdateValues = async () => {
    const body = {
      fullName: signUpData?.fullName,
      phoneNumber: signUpData?.phoneNumber,
      email: user?.email,
    };
    // await dispatch(updateUserDetails(body));
    setIsEdit(false);
    getUserDetailsByIdD();
  };
  const isValidFn = () => {
    const returnF =
      !signUpData?.fullName ||
      !signUpData?.phoneNumber ||
      signUpData?.phoneNumber?.length < 10;
    return returnF;
  };
  const handleClosePassword = () => {
    setShowPassWordModel(false);
  };
  const handleOpenPassword = () => {
    setShowPassWordModel(true);
  };
  const changePassSuccess = () => {
    setShowPassWordModel(false);
    setShowSuccessPass(true);
  };
  // console.log("allProducts", allProducts);

  console.log("user >>>>", user);

  return (
    <>
      <MyProfileInfoStyled>
        <h2>My Account</h2>
        {!isEdit && (
          <div className="main_section">
            {/* <div className="top_btn_wrp">
              <button onClick={handleEdit}>
                <img src={editIcon} alt="edit" />
                edit
              </button>
            </div> */}
            <div className="details_sec_top">
              <div className="vendor_logo">
                {user?.fullName?.split(" ")[0][0]}
                {user?.fullName?.split(" ")?.length > 1
                  ? user?.fullName?.split(" ")[1][0]
                  : ""}
              </div>
            </div>
            <div className="name_wrap">
              <div className="vendor_name">{user?.fullName}</div>
            </div>
            <div className="id_wrap">
              <div className="vendor_id">{user?.vendorId}</div>
            </div>
            <div className="address_details">
              <div className="inner_wrp">
                <p className="email">
                  Email: <strong>{user?.email}</strong>
                </p>

                <p className="mb-0">
                  Address:{" "}
                  {`${user?.address1 ? `${user?.address1},` : ``} ${
                    user?.address2 ? `${user?.address2},` : ``
                  } ${user?.city ? `${user?.city},` : ``} ${
                    user?.state ? `${user?.state},` : ``
                  }`}
                </p>
                <p>{`${user?.zip ? `${user?.zip},` : ``} ${
                  user?.country ? `${user?.country}` : ``
                }`}</p>
                <p>Phone Number: {user?.phoneNumber}</p>
              </div>
            </div>
            {/* <div className="change_psw">
              <button onClick={handleOpenPassword}>Change Password</button>
            </div> */}
          </div>
        )}
        {isEdit && (
          <EditProfile user={user} handleCancelEdit={handleCancelEdit} />
        )}
      </MyProfileInfoStyled>
    </>
  );
};

export default MyProfileInfo;
