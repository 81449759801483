import React, { useEffect, useState } from "react";
import { ManageDoctorsStyled } from "./ManageDoctors.styled";
import { Table, Badge, Dropdown, Space, Pagination } from "antd";

import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddNewUserModel from "./AddNewDoctorsModel";
import moment from "moment";
import Loader from "../../components/Common/Loader";
import doctor_img from "./../../img/young-hispanic-woman-working-scientist-laboratory-holding-safety-glasses-smiling-happy-pointing-with-hand-finger-side 1(1).png";
import Select from "react-select";
import add_icon from "../../images/img/add.png";
import add_icon1 from "../../images/img/download-icon.png";

import {
  getAllDoctors,
  updateDoctorStatusCall,
  updateDoctorStatusApproveCall,
  updateDoctorStatusRejectCall,
} from "./../../redux/slices/doctors/doctorsService";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import CustomModal from "../../components/CustomModel";
import Switch from "react-switch";

const ManageDoctors = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [thankyouMessage, setThankyouMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [showAddNewUserModel, setShowAddNewUserModel] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [signUpData, setSignUpData] = useState({} as any);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showSuccessMessageText, setShowSuccessMessageText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedType, setSelectedType] = useState({} as any);
  const [selectedStatus, setSelectedStatus] = useState({} as any);
  const [selectedDoctor, setSelectedDoctor] = useState({} as any);
  const [selectedAction, setSelectedAction] = useState("");
  const [conformClosingModel, setConformClosingModel] = useState(false);
  const [conformClosingModelText, setConformClosingModelText] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const [pageNo, setPageNo] = useState(0);

  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user, isAdminSite } = useSelector(
    (state: any) => state?.auth
  );
  const { doctorsList } = useSelector((state: any) => state?.doctors);
  console.log("doctorsList", doctorsList);

  useEffect(() => {
    getAllUsersCall();
  }, []);
  useEffect(() => {
    getAllUsersCall();
  }, [
    searchText,
    selectedType?.label,
    selectedStatus?.label,
    pageNo,
    pageSize,
  ]);

  const getAllUsersCall = async () => {
    let body = {} as any;
    // if (searchText) {
    body.searchText = searchText;
    // }
    if (selectedType?.label && selectedType?.label !== "All") {
      body.type = selectedType?.value;
    }
    if (selectedStatus?.label && selectedStatus?.label !== "All") {
      body.status = selectedStatus?.value;
    }

    body.pageSize = pageSize;
    body.pageNo = pageNo;
    console.log("body", body);
    setIsLoading(true);
    await dispatch(getAllDoctors(body));
    setIsLoading(false);
  };
  const handlePageChange = (val: any, val1: any) => {
    setPageSize(val1);
    setPageNo(val);
  };
  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const handleCancel = () => {
    setShowAddNewUserModel(false);
  };
  const addUserSuccess = () => {
    setShowAddNewUserModel(false);
    setShowSuccessMessage(true);
  };
  const handleCreatenewApp = () => {
    setSelectedUser("");
    setShowAddNewUserModel(true);
  };
  const handleChangeValue = (e: any) => {
    let { name, value } = e.target;
    setPageNo(0);
    setSearchText(value);
  };
  const instituteAction = (itemV: any, actionN: any) => {
    setSelectedAction(actionN);
    setSelectedDoctor(itemV);
    if (actionN === "edit") {
    } else if (actionN == "approve") {
      setConformClosingModel(true);
      setConformClosingModelText(
        "Are you sure do you want to <span>Approve</span> below Doctor."
      );
    } else if (actionN == "reject") {
      setConformClosingModel(true);
      setConformClosingModelText(
        "Are you sure do you want to <span>Reject</span>  below Doctor."
      );
    }
  };
  const pageonChange = (page: any) => {
    console.log("page", page);
    setCurrentPage(page);
  };
  const handleChangeRole = (selectedOption: any) => {
    setPageNo(0);
    setSelectedType(selectedOption);
  };
  const handleChangeRole1 = (selectedOption: any) => {
    setPageNo(0);
    setSelectedStatus(selectedOption);
  };
  const handleChangeSwitch = async (itemV: any, fPro: any) => {
    // setShowOnlyOfferProducts(fPro);
    console.log("fPro", itemV, fPro);
    const body = {
      id: itemV?.id,
      status: fPro ? "approved" : "inactive",
    };
    await dispatch(updateDoctorStatusCall(body));
    getAllUsersCall();
  };
  const handleSubmitBtnConform = async (reasonText: any) => {
    if (selectedDoctor?.id) {
      if (selectedAction === "approve") {
        const body = {
          id: selectedDoctor?.id,
        };
        await dispatch(updateDoctorStatusApproveCall(body));
        getAllUsersCall();
        setShowSuccessMessage(true);
        setShowSuccessMessageText("Successfullt Approved the Doctor");
      } else if (selectedAction === "reject") {
        const body = {
          id: selectedDoctor?.id,
          reason: reasonText,
        };
        await dispatch(updateDoctorStatusRejectCall(body));
        getAllUsersCall();
        setShowSuccessMessage(true);
        setShowSuccessMessageText("Successfullt Rejected the Doctor");
      }
      console.log("se", selectedAction, selectedDoctor);
    }
  };

  const items = [
    {
      key: "approve",
      label: <span className="drop-down-single-item-sec">Approve</span>,
    },
    {
      key: "reject",
      label: <span className="drop-down-single-item-sec">Reject</span>,
    },
    {
      key: "edit",
      label: <span className="drop-down-single-item-sec">Edit</span>,
    },
    {
      key: "delete",
      label: <span className="drop-down-single-item-sec">Delete</span>,
    },
  ];
  const columns = [
    {
      title: "Code",
      width: 100,
      dataIndex: "id",
      key: "0",
      fixed: "left",
    },
    {
      title: "Image",
      key: "3",
      width: 100,
      render: (item: any) => {
        return (
          <>
            {item?.image ? (
              <img className="doctor-img-sec" src={item?.image} />
            ) : (
              <span className="no-image-sec-char">{item?.name?.charAt(0)}</span>
            )}
          </>
        );
      },
    },
    // {
    //   title: "Email",
    //   // dataIndex: "user",
    //   key: "3",
    //   width: 230,
    //   render: (item: any) => {
    //     return <>{item?.user?.email}</>;
    //   },
    // },
    // {
    //   title: "Ref No",
    //   width: 100,
    //   dataIndex: "RefNo",
    //   key: "1",
    // },

    {
      title: "Name",
      dataIndex: "name",
      key: "2",
      width: 200,
    },

    {
      title: "Phone",
      dataIndex: "user",
      key: "3",
      width: 150,
      render: (item: any) => {
        return <>{item?.phone}</>;
      },
    },

    {
      title: "Gender",
      dataIndex: "gender",
      key: "42",
      width: 90,
      render: (item: any) => {
        return (
          <>
            <span className="text-capitalize">{item}</span>
          </>
        );
      },
    },
    {
      title: "Specializations",
      dataIndex: "specialization",
      key: "4",
      width: 200,
    },
    {
      title: "city",
      dataIndex: "city",
      key: "7",
      width: 150,
      render: (item: any) => {
        return (
          <>
            <span className="text-capitalize">{item}</span>
          </>
        );
      },
    },
    {
      title: "Careated Date",
      dataIndex: "city",
      key: "7",
      width: 150,
      render: (item: any) => {
        return <>{moment(item).format("DD/MM/YYYY")}</>;
      },
    },
    { title: "Type", dataIndex: "type", key: "8", width: 70 },
    {
      title: "Is Active",
      fixed: "right",
      key: "8",
      width: 90,
      render: (itemV: any) => {
        return (
          <>
            <Switch
              onChange={(flagv) => {
                handleChangeSwitch(itemV, flagv);
              }}
              checked={
                itemV?.status === "approved" || itemV?.status === "requested"
                  ? true
                  : false
              }
            />
          </>
        );
      },
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 90,
      // dataIndex: "_id",
      render: (item: any) => {
        // Dynamically set dropdown options based on doctor status
        const actionItems = [
          {
            key: item?.status === "approved" ? "reject" : "approve",
            label: (
              <span className="drop-down-single-item-sec">
                {item?.status === "approved" ? "Reject" : "Approve"}
              </span>
            ),
          },
          {
            key: "edit",
            label: <span className="drop-down-single-item-sec">Edit</span>,
          },
          {
            key: "delete",
            label: <span className="drop-down-single-item-sec">Delete</span>,
          },
        ];

        return (
          <Space size="middle">
            <Dropdown
              placement="topLeft"
              className="menu-items-sec-all"
              menu={{
                items: actionItems,
                onClick: ({ key }) => {
                  instituteAction(item, key);
                },
              }}
            >
              <span className="hover-menu-item-sec">...</span>
            </Dropdown>
          </Space>
        );
      },
    },
  ] as any;
  const rolesList = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Virtual",
      value: "virtual",
    },
    {
      label: "Opd",
      value: "opd",
    },
  ] as any;

  const rolesList1 = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
  ] as any;
  console.log("conformClosingModel", conformClosingModel);
  return (
    <>
      <ManageDoctorsStyled>
        <div className="freshbag-wrapper">
          {isLoading && <Loader />}
          <div className="content getinTouchPage">
            <div>
              <BreadCrumb list={[{ title: "Doctors" }]} />
              <div className="profileinfoHeader">
                <div className="top-sec-header-sec">
                  <span className="edit-p-text">Manage Doctors</span>
                  <div onClick={handleCreatenewApp}>
                    <span className="profileEdit">
                      Add New Doctor &nbsp; <img src={add_icon} />
                    </span>
                  </div>
                </div>
                <p>View All Doctors</p>
              </div>
            </div>
            <div className="create-new-institute-sec-content-all">
              <div className="student-fields-sec-content-all deltape-form">
                <div className="student-info-row">
                  <Form.Group className="delta-signup-md" controlId="email">
                    <Form.Label> Search</Form.Label>
                    <Form.Control
                      placeholder="Search: Name, Email, Phone"
                      // autoFocus
                      // isInvalid={errorData?.studentName}
                      name="studentName"
                      type="text"
                      value={searchText}
                      onChange={(e) => handleChangeValue(e)}
                    />
                  </Form.Group>
                </div>

                <div className="delta-select-column">
                  <label>Select Type </label>
                  <Select
                    value={selectedType?.value ? selectedType : null}
                    placeholder="Select Role"
                    onChange={handleChangeRole}
                    options={rolesList}
                    className="delta-select"
                    classNamePrefix="delta-select"
                  />
                </div>
                <div className="delta-select-column">
                  <label>Select Status </label>
                  <Select
                    value={selectedStatus?.value ? selectedStatus : null}
                    placeholder="Select Role"
                    onChange={handleChangeRole1}
                    options={rolesList1}
                    className="delta-select"
                    classNamePrefix="delta-select"
                  />
                </div>
                <div className="download-icon-sec">
                  <img src={add_icon1} />
                </div>
                {/* <div className="delta-select-column submit-filter-btn-sec">
                  <button className="deltape-button">SUBMIT</button>
                </div> */}
              </div>
            </div>
            <div>
              <div className="total-count-row"></div>
              <div className="institutes-filters"></div>
              <div className="all-institutes-data rapha-table-view">
                <Table
                  columns={columns}
                  pagination={false}
                  dataSource={doctorsList?.doctors}
                  scroll={{ x: 1500, y: 500 }}
                  rowClassName={(record, index) =>
                    `row_selected_priority_${record?.priority}`
                  }
                />

                <div className="rapha-table-pagination">
                  <div>
                    <span>
                      Showing {doctorsList?.doctors?.length} of{" "}
                      {doctorsList?.doctorCount} Users
                    </span>
                  </div>
                  <div>
                    <Pagination
                      onChange={handlePageChange}
                      pageSize={pageSize}
                      current={pageNo}
                      total={doctorsList?.doctorCount}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {doctorsList?.doctors?.length === 0 && (
            <div className="no-results">
              <p>No Users available</p>
            </div>
          )}
          <AddNewUserModel
            handleCancel={handleCancel}
            selectedUser={selectedUser}
            addUserSuccess={addUserSuccess}
            show={showAddNewUserModel}
            getAllUsersCall={getAllUsersCall}
          />
          <CustomModal
            show={conformClosingModel}
            titleText={conformClosingModelText}
            deleteText="CONFIRM"
            cancelText="Cancel"
            doctorD={selectedDoctor}
            selectedAction={selectedAction}
            dynamicClassName={`error_message1`}
            onCancel={() => {
              setConformClosingModel(false);
              // history.push("/jobcardsummary");
            }}
            onDelete={() => {
              setConformClosingModel(false);
              handleSubmitBtnConform("");
              // history.push("/jobcardsummary");
            }}
            onRejectCall={(rText: any) => {
              setConformClosingModel(false);
              handleSubmitBtnConform(rText);
              // history.push("/jobcardsummary");
            }}
          />

          <CustomModal
            show={showSuccessMessage}
            titleText={showSuccessMessageText}
            deleteText="Ok"
            cancelText=""
            onDelete={() => {
              setShowSuccessMessage(false);
              handleCancel();
            }}
          />
        </div>
      </ManageDoctorsStyled>
    </>
  );
};

export default ManageDoctors;
