import styled from "styled-components";

export const ModalImageUploadStyled = styled.div`
  .modal-image-upload .upload-button {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    transition: background-color 0.3s;
  }

  .modal-image-upload .upload-button:hover {
    background-color: #0056b3;
  }

  .image-preview {
    margin-top: 15px;
    text-align: center;
  }
`;
