import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./styles.css";

const CustomModal = (props) => {
  console.log("props", props);
  const [reasonText, setReasonText] = useState("");
  const [isErrorReason, setIsErrorReason] = useState("");
  const onDelete1 = () => {
    if (props?.selectedAction == "reject") {
      if (reasonText) {
        props?.onRejectCall(reasonText);
        setReasonText("");
      } else {
        setIsErrorReason("error");
      }
    } else {
      props.onDelete();
    }
  };
  return (
    <Modal
      {...props}
      className="conformation-model-popup-sec-note"
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body
        className={`modalBodyDefault alert-model-popup-sec dynamic_class_${props?.dynamicClassName}`}
      >
        <p className="modalBodyText select-status-title-txt">
          <p
            dangerouslySetInnerHTML={{
              __html: props.titleText,
            }}
          ></p>
        </p>
        {props?.doctorD?.id && (
          <div>
            <p>Name: {props?.doctorD?.name}</p>
            <p>Code: {props?.doctorD?.id}</p>

            {props?.selectedAction == "reject" && (
              <>
                <div>
                  <label>
                    <strong>Reason for Reject</strong>{" "}
                  </label>
                </div>
                <textarea
                  className={`textarea_${isErrorReason}`}
                  onChange={(e) => {
                    setIsErrorReason("");
                    setReasonText(e.target.value);
                  }}
                  height="120px"
                  width="100%"
                />
              </>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {props.cancelText != "" && (
          <Button onClick={props.onCancel} className="modalCandelBtn">
            {props.cancelText}
          </Button>
        )}
        <Button onClick={onDelete1} className="modalDeleteBtn">
          {props.deleteText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
