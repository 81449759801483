import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";
import { getAllClientsQuery } from "../../../Scenes/graphqlConfig";

export const createUserAPI = createAsyncThunk(
  "reports/createUserAPI",
  async (body: any) => await post(`${SERVER_IP}/user/create`, body)
);
export const getUserDetailsAPI = createAsyncThunk(
  "reports/getUserDetailsAPI",
  async (iID: any) => await get(`${SERVER_IP}/user/${iID}`)
);

export const updateUserDetailsAPI = createAsyncThunk(
  "reports/updateUserDetailsAPI",
  async (body: any) => await put(`${SERVER_IP}/user/update`, body)
);
export const getAllClients = createAsyncThunk(
  "Profile/getAllClients",
  async (body: any) =>
    await post(`${SERVER_IP}/graphql`, getAllClientsQuery(body))
);
