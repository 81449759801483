import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getClicksReportsAPI } from "./reportsService";

export interface ReportsState {
  loading: boolean;
  error: any;
  clicksReportData: any;
}

const initialState: ReportsState = {
  loading: false,
  error: null,
  clicksReportData: {},
};

export const reportsSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state: ReportsState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateErrorMessage: (
      state: ReportsState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    //getAllPricelineSales
    // builder.addCase(getYahooFeeds.pending, (state: any) => {
    //   state.loading = true;
    //   state.error = null;
    // });
    // builder.addCase(
    //   getYahooFeeds.fulfilled,
    //   (state: ReportsState, action: PayloadAction<any>) => {
    //     state.error = null;
    //     state.loading = false;
    //     state.yahooFeeds = action.payload;
    //   }
    // );
    // builder.addCase(
    //   getYahooFeeds.rejected,
    //   (state: ReportsState, action: any) => {
    //     state.loading = false;
    //   }
    // );
    //handleGetAuthTokenAPI

    //getClicksReportsAPI
    builder.addCase(getClicksReportsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
      state.clicksReportData = {};
    });
    builder.addCase(
      getClicksReportsAPI.fulfilled,
      (state: ReportsState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.clicksReportData = action.payload?.data;
      }
    );
    builder.addCase(
      getClicksReportsAPI.rejected,
      (state: ReportsState, action: any) => {
        state.loading = false;
        state.clicksReportData = {};
      }
    );
  },
});

export const { setLoading, updateErrorMessage } = reportsSlice.actions;

export default reportsSlice.reducer;
