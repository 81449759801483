import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";
import { getAllDoctorsQuery } from "../../../Scenes/graphqlConfig";

export const getAllDoctors = createAsyncThunk(
  "Profile/getAllDoctors",
  async (body: any) =>
    await post(`${SERVER_IP}/graphql`, getAllDoctorsQuery(body))
);
export const updateDoctorStatusCall = createAsyncThunk(
  "Profile/updateDoctorStatusCall",
  async (body: any) =>
    await put(`${SERVER_IP}/api/v1/doctor/${body?.id}/toggle`, {
      status: body?.status,
    })
);
export const updateDoctorStatusApproveCall = createAsyncThunk(
  "Profile/updateDoctorStatusApproveCall",
  async (body: any) =>
    await put(`${SERVER_IP}/api/v1/doctor/${body?.id}/approve`, {})
);
export const updateDoctorStatusRejectCall = createAsyncThunk(
  "Profile/updateDoctorStatusRejectCall",
  async (body: any) =>
    await put(`${SERVER_IP}/api/v1/doctor/${body?.id}/reject`, {
      reason: body?.reason,
    })
);
