import styled from "styled-components";

export const HeaderStyled = styled.div`
  #navbarCollapse {
    display: block !important;
    align-items: center;
    width: 100%;
    height: 72px;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    /* background-color: #e2e2e2; */
    /* background-color: #e2e2e2;  1db954*/
  }
  #headerWrapper {
    height: 72px;
    background-color: #fff;
    position: fixed;
    width: 100%;
    z-index: 99999;
  }
  .mainLogoImage {
    max-width: 75px;
  }
  .logo-main-item {
    color: #0061f6;
    /* font-family: Roboto; */
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
  #navbarCollapse ul {
    flex-direction: row;
    /* margin-right: auto !important;
      margin-left: auto !important;
      display: flex;
      flex-direction: column;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none; */
  }
  .allItemsSection {
    display: flex;
    justify-content: space-between;
    padding: 16px 62px;
  }
  .row-item-secion {
    select,
    select:hover,
    select:focus-visible,
    select:active {
      color: #000;
      /* font-family: Lexend; */
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.27px;
      border: none;
    }
  }
  .row-item-secion-select,
  .icons-sec-box {
  }
  .icons-sec-box {
    gap: 24px;
    display: flex;
  }
  .search-sec-box {
    border: 1px solid #e2e2e2;
    padding: 10px 10px;
    display: flex;
    width: 45%;
    border-radius: 4px;
    border: 1px solid #6a6a6a;

    img {
      position: relative;
      //   top: 9px;
      margin-right: 4px;
    }
    input {
      border: none;
      color: #b0b0b0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      letter-spacing: 0.14px;
      width: 100%;
      height: 24px;
    }
  }
  .sub-header-sec {
    padding-top: 85px;
    width: 100%;
    height: 48px;
    background: #fff;
  }
  .sub-header-sec-content {
    background: #fff;
    display: flex;
    justify-content: end;
    padding: 0px 30px;
    padding-top: 12px;
    height: 48px;
    span {
      color: #000;
      font-size: 15px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.225px;
    }
    .vertical-line {
      background: #6a6a6a;
      width: 1px;
      height: 20px;
      margin: 0px 30px;
      display: block;
    }
    .sub-heaser-doller {
      color: #3b8f46;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .profile-logo {
    div {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
    }
  }
  .right-side-icons-sec {
    display: flex;
    gap: 20px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
  .signed-user-name {
    margin-right: 5px;
  }
  .log-out-btn-header {
    color: #9d3c3c;
    margin-top: 5px;
    top: 5px;
    position: relative;
    cursor: pointer;
  }
  .signInButton1 {
    background: #008080;
    border: none;
    color: #fff;
    height: 40px;
    width: 100px;
    border-radius: 8px;
  }
`;
