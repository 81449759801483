export const getAllDoctorsQuery = (body) => {
  const queryObj = {
    query: `{ doctors (searchText: "${body?.searchText}", type: "${
      body?.type ? body?.type : "all"
    }",  count: ${body?.pageSize}, page: ${body?.pageNo}, activeStatus: "${
      body?.status ? body?.status : "all"
    }") {
      id
      name
      languages
      specialization
      medical_registration_body
      highest_education
      zip
      city
      gst
      type
      registration_number
      registration_expiry_date
      work_experience_years
      rating
      virtual_consultation_cost
      chat_consultation_cost
      call_consultation_cost
      gender
      image
      slot_start_time
      slot_end_time
      available_in_90
       state
    medical_registration_file
    pan_file
    aadhar_file
    aadhar_number
    pan_number
    status
      user{
        id
        email
        phone
        secondary_phone
        secondary_email
      }
      bank_details{
        id
        account_number
        name
        ifsc_code
      }
}
    doctorCount(searchText: "", type: "all", activeStatus: "all")
  }
`,
  };
  return queryObj;
};

export const getAllClientsQuery = (body) => {
  const queryObj = {
    query: `{clients(type:"all",  activeStatus: "active"){
  id
  name
  logo_url
  user_max
  user_count
  status
  parentClient{
  id
  name
  }
}}
`,
  };
  return queryObj;
};
