import styled from "styled-components";

export const IndexStyled = styled.div`
  margin-bottom: 10px;
  .line-item-text {
    font-size: 14px;
    font-weight: 400;
    color: #8c8c8c;
  }
  .line-item-dd img {
    position: relative;
    // top: 3px;
  }
`;
