import styled from "styled-components";

export const SigninStyled = styled.div`
  .password_field {
    position: relative;
    .eyeIcon {
      position: absolute;
      right: 10px;
      top: 44px;
      cursor: pointer;
    }
  }
`;