import React from "react";
import { Modal, Button } from "react-bootstrap";
import AddNewUser from "./AddNewDoctors";

const AddNewUserModel = (props: any) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-user-model-popup"
    >
      <Modal.Body
        className={`modalBodyDefault alert-model-popup-sec dynamic_class_${props?.dynamicClassName}`}
      >
        <AddNewUser {...props} isEdit={true} />
      </Modal.Body>
    </Modal>
  );
};

export default AddNewUserModel;
