import React, { useEffect, useState } from "react";

import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import heroimage from "../../img/hero-image-2.png";
import frame565 from "../../img/Frame-565.png";
import app_Store from "../../img/App_Store.png";
import google_play from "../../img/Google_play.png";
import { DashboardStyled } from "./DashboardStyled";
import BreadCrumb from "./../../components/BreadCrumb/BreadCrumb";
import influencers from "../../img/Influencers.png";
import saveIcon from "../../images/download.svg";
import downloadIcon from "../../images/save.svg";
// import SaleStatus from "./SaleStatus/SaleStatus";
// import { SaleStatusStyled } from "./SaleStatus/SaleStatus.styled";
import { getAuthToken } from "./../../Scenes/common";
import Loader from "./../../components/Common/Loader";
import { Pagination } from "antd";

import {
  getAllPriceLineFeedsApi,
  getAllYahooFeedsApi,
  createUserClicksApi1,
  createUserClicksApi2,
  getAllPricelineSales,
} from "./../../redux/slices/auth/authService";
import {
  getPerformanceReportAPI,
  getEarningsReportAPI,
} from "./../../redux/slices/reports/reportsService";
import moment from "moment";

import "antd/dist/antd.css";
import { Table } from "antd";
import { Chart } from "react-google-charts";

import { DatePicker, Space } from "antd";
import Filter from "./Filter";
import crossIcon from "../../images/crossRed.svg";

import iIcon from "../../images/Group.svg";
import cursorIcon from "../../images/cursor-fill.svg";
import totalCpcIcon from "../../images/TotalCPCicon.svg";
import barIcon from "../../images/bar-chart-fill.svg";
import gropIcon from "../../images/group-3-fill.svg";
import cartIcon from "../../images/shopping-cart-2-fill.svg";
import { getClicksReportsAPI } from "./../../redux/slices/reports/reportsService";

const { RangePicker } = DatePicker;

// import {setallProductsData } from "../../redux/slices/productCart/productCartSlice";
const Dashboard = () => {
  const [addressEnabled, setAddressEnabled] = useState(false);
  const [allData, setAllData] = useState([]);
  const [totalClicks, setTotalClicks] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [avgCPC, setAvgCPC] = useState(0);
  const [totalCpc, setTotalCpc] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState("Last7days");
  const [dateWiseReports, setDateWiseReports] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [pageNo, setPageNo] = useState(1);

  const [dataGraph, setDataGraph] = useState([
    ["Date", "Clicks", "Estimated Earnings", "CPC"],
    ["", "", "", ""],
  ]);

  const [combinedData, setCombinedData] = useState([]);
  const [timeChecked, setTimeChecked] = useState("");
  const [sDate, setSDate] = useState(
    moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
  );
  const [eDate, setEDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const history = useHistory();
  const dispatch = useDispatch();
  const { allYahooFeedsData, allPriceLineFeedsData, user } = useSelector(
    ({ auth }) => auth
  );
  const { performanceReportData, earningsReportData } = useSelector(
    ({ reports }) => reports
  );
  const {
    clicksReportData,
    getAllClicksSummaryData,
    getAllClicksSummarybyDateData,
  } = useSelector(({ reports }) => reports);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query1 = useQuery();
  const vendorId_link = query1.get("vendorId");
  console.log("getAllClicksSummaryData", getAllClicksSummaryData);
  console.log("getAllClicksSummarybyDateData", getAllClicksSummarybyDateData);
  useEffect(() => {
    // getAllClicksInfoData();
    if (!user?.id) {
      history.push("/signIn");
    }
    const sDate = moment(new Date()).subtract(7, "days").format("YYYY-MM-DD");
    const eDate = moment(new Date()).format("YYYY-MM-DD");
    const body = {
      startDate: sDate,
      endDate: eDate,
    };
    getLatestReportsCall(body);
    // handleCheckboxChange("Yesterday");
  }, []);
  useEffect(() => {
    const dalebbb = [];
    dalebbb.push(["Date", "Clicks", "Estimated Earnings", "CPC"]);
    // getAllClicksInfoData();
    let dayWiseC = [];
    if (combinedData?.length) {
      (combinedData || [])?.map((itttm) => {
        dalebbb.push([
          itttm?.report_date,
          parseInt(itttm?.clicks),
          parseFloat(itttm?.earnings),
          parseFloat(itttm?.cpc),
        ]);
        dayWiseC.push({
          clicks: itttm?.clicks,
          earnings: itttm?.earnings,
          cpc: itttm?.cpc,
          report_date: itttm?.report_date,
        });
      });
      setDateWiseReports(dayWiseC);
      setDataGraph(dalebbb);
    }
  }, [combinedData]);
  console.log("clicksReportData", clicksReportData);

  const handleDateChange = (seDate) => {
    console.log("seDate", seDate);
    const sDate = moment(seDate).format("YYYY-MM-DD");
    setSDate(sDate);
    const body = {
      startDate: sDate,
      endDate: eDate,
    };
    setIsChecked("");
    getLatestReportsCall({
      ...body,
      aggregation: timeChecked?.toLocaleLowerCase(),
    });
  };
  const handleDateChange1 = (seDate) => {
    const eDate = moment(seDate).format("YYYY-MM-DD");
    setEDate(eDate);
    const body = {
      startDate: sDate,
      endDate: eDate,
    };
    setIsChecked("");
    getLatestReportsCall({
      ...body,
      aggregation: timeChecked?.toLocaleLowerCase(),
    });
  };
  const handlePageChange = (val, val1) => {
    setPageSize(val1);
    setPageNo(val);
  };
  useEffect(() => {
    const body = {
      startDate: sDate,
      endDate: eDate,
    };
    getLatestReportsCall(body);
  }, [pageNo, pageSize]);

  const getLatestReportsCall = async (body) => {
    console.log("body : ", body);
    setIsLoading(true);
    await dispatch(getClicksReportsAPI(body));
    setIsLoading(false);
  };

  const options = {
    bars: "vartical", // Required for Material Bar Charts.
    hAxis: { format: "decimal" },
    height: 400,
    colors: ["#560BAD", "#FF630B", "#50CC16"],
    legend: { position: "none" },
  };

  // getPerformanceReportAPI,getEarningsReportAPI
  const scrollObj = { x: 950, y: 500 };
  const totalsData = earningsReportData?.reports?.totals;
  const totalTable = earningsReportData?.reports?.earnings;
  console.log("allData1", totalsData);

  const handleCheckboxChange = (day) => {
    setIsChecked(day);
    let payload = {};
    if (day === "Yesterday") {
      var d = new Date();
      d.setDate(d.getDate() - 1);
      payload = {
        startDate: moment(d).format("YYYY-MM-DD"),
        endDate: moment(d).format("YYYY-MM-DD"),
      };
    } else if (day === "Lastweek") {
      payload = {
        startDate: moment()
          .subtract(1, "weeks")
          .startOf("week")
          .format("YYYY-MM-DD"),
        endDate: moment()
          .subtract(1, "weeks")
          .endOf("week")
          .format("YYYY-MM-DD"),
      };
    } else if (day === "LastMonth") {
      const lastmonthfirstdate = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");

      const lastmonthlastdate = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");

      payload = {
        startDate: lastmonthfirstdate,
        endDate: lastmonthlastdate,
      };
    } else if (day === "Last7days") {
      const dateTo = moment(new Date()).format("YYYY-MM-DD");
      const dateFrom = moment(new Date())
        .subtract(7, "days")
        .format("YYYY-MM-DD");
      payload = {
        startDate: dateFrom,
        endDate: dateTo,
      };
    } else if (day === "Last30days") {
      const dateTo = moment(new Date()).format("YYYY-MM-DD");
      const dateFrom = moment(new Date())
        .subtract(30, "days")
        .format("YYYY-MM-DD");
      payload = {
        startDate: dateFrom,
        endDate: dateTo,
      };
    } else if (day === "Last90days") {
      const dateTo = moment(new Date()).format("YYYY-MM-DD");
      const dateFrom = moment(new Date())
        .subtract(90, "days")
        .format("YYYY-MM-DD");
      payload = {
        startDate: dateFrom,
        endDate: dateTo,
      };
    }
    setSDate(payload?.startDate);
    setEDate(payload?.endDate);
    getLatestReportsCall({
      ...payload,
      aggregation: timeChecked?.toLocaleLowerCase(),
    });
  };
  const timeFilterChange = (time) => {
    setTimeChecked(time);
    const payload = {
      startDate: sDate,
      endDate: eDate,
      aggregation: time?.toLowerCase(),
    };
    getLatestReportsCall(payload);
  };

  const clearAllFunc = () => {
    const body = {
      startDate: "",
      endDate: "",
    };
    setSDate(moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"));
    setEDate(moment(new Date()).format("YYYY-MM-DD"));
    setIsChecked("");
    getLatestReportsCall(body);
  };
  const data2 = [
    ["Year", "Sales", "Expenses", "Profit"],
    ["2014", 1000, 400, 200],
    ["2015", 1170, 460, 250],
    ["2016", 660, 1120, 300],
    ["2017", 1030, 540, 350],
  ];
  console.log("dataGraph", dataGraph);
  console.log("options", options);
  return (
    <>
      <DashboardStyled>
        <div className="home-container">
          {isLoading && <Loader />}
          <div className="home-content-container">
            <BreadCrumb list={[{ title: "Dashboard" }]} />

            <h1 className="main_header">Dashboard</h1>
            <div className="redclub-filters">
              <div className="redclub-dates-row row">
                <div className="col-8 filter_lft_side">
                  <Form.Group className="delta-signup-md" controlId="email">
                    <Form.Label className="date_range_text">
                      {" "}
                      Date Range
                    </Form.Label>

                    <Filter
                      isChecked={isChecked}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                    <div className="custom_heading">
                      Custom Date Range
                      <img src={iIcon} alt="custom" />
                    </div>
                    <div className="range-select-picker">
                      <div>
                        <label>Start Date</label>
                        <DatePicker
                          onChange={handleDateChange}
                          value={moment(sDate, "YYYY-MM-DD")}
                          disabledDate={(d) => !d || d.isAfter(eDate)}
                        />
                      </div>
                      <div>
                        <label>End Date</label>
                        <DatePicker
                          disabledDate={(d) => !d || d.isBefore(sDate)}
                          onChange={handleDateChange1}
                          value={moment(eDate, "YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <br />
              <div className="text_chart_wrap">
                <div className="body-content-top">
                  <div className="report-box-row-all">
                    <div className="report-box-row report-box-row-1">
                      <div className="mb-4">
                        {/* <img src={cursorIcon} alt="click" /> */}
                        <span className="text-red-top">Pending Bookings</span>
                      </div>
                      <div>
                        <span className="count-red-top">
                          {clicksReportData?.bookings_count || "12"}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="report-box-row report-box-row-2">
                      <div className="mb-4">
                        {/* <img src={totalCpcIcon} alt="click" /> */}
                        <span className="text-red-top">Accepted Bookings</span>
                      </div>
                      <div>
                        <span className="count-red-top">
                          {clicksReportData?.cancelled_bookings_count || "120"}
                        </span>
                      </div>
                    </div>
                    <div className="report-box-row report-box-row-3">
                      <div className="mb-4">
                        {/* <img src={totalCpcIcon} alt="click" /> */}
                        <span className="text-red-top">Rejected Bookings</span>
                      </div>
                      <div>
                        <span className="count-red-top">
                          {clicksReportData?.unseen_reports_count || "20"}
                        </span>
                      </div>
                    </div>
                    <div className="report-box-row report-box-row-1">
                      <div className="mb-4">
                        {/* <img src={totalCpcIcon} alt="click" /> */}
                        <span className="text-red-top">Open Bookings</span>
                      </div>
                      <div>
                        <span className="count-red-top">
                          {clicksReportData?.client_confirmation_pending_count ||
                            "16"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </DashboardStyled>
    </>
  );
};

export default Dashboard;
