import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { NursesStyled } from "./Nurses.styled";
import ModalImageUpload from "../../components/ModalImageUpload/ModalImageUpload";
import { Image } from "antd";
import { useDispatch } from "react-redux";
import { addNurseAPI } from "../../redux/slices/nurses/nursesService";
import { toast } from "react-toastify";

const CreateUser = (props: any) => {
  const dispatch = useDispatch();
  const [signUpData, setSignUpData] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    bank_details: {
      name: "",
      account_number: "",
      ifsc_code: "",
      bank_name: "",
    },
    date_of_birth: "",
    qualification: "",
    languages: null,
    address: "",
    educationCertificate: null,
    profilePhoto: null,
  });

  const [errorData, setErrorData] = useState({
    first_name: false,
    last_name: false,
    email: false,
    phone_number: false,
    bank_details: {
      name: false,
      account_number: false,
      ifsc_code: false,
      bank_name: false,
    },
    languages: false,
  });

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showImgModal, setShowImgModal] = useState(false);
  const [uploadField, setUploadField] = useState<string | null>(null);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name.startsWith("bank_details.")) {
      const fieldName = name.split(".")[1];
      setSignUpData((prevData: any) => ({
        ...prevData,
        bank_details: { ...prevData.bank_details, [fieldName]: value },
      }));
      setErrorData((prevError: any) => ({
        ...prevError,
        bank_details: { ...prevError.bank_details, [fieldName]: false },
      }));
    } else {
      setSignUpData((prevData: any) => ({ ...prevData, [name]: value }));
      setErrorData((prevError: any) => ({ ...prevError, [name]: false }));
    }
  };

  const handleSaveClick = async () => {
    let isValid = true;

    const mandatoryFields = [
      "first_name",
      "last_name",
      "email",
      "phone_number",
      "bank_details.name",
      "bank_details.account_number",
      "bank_details.ifsc_code",
      "languages",
    ];

    const newErrorData: any = {
      first_name: !signUpData?.first_name,
      last_name: !signUpData?.last_name,
      email: !signUpData?.email,
      phone_number: !signUpData?.phone_number,
      bank_details: {
        name: !signUpData?.bank_details.name,
        account_number: !signUpData?.bank_details.account_number,
        ifsc_code: !signUpData?.bank_details.ifsc_code,
      },
      languages: !signUpData?.languages,
    };

    // Check for errors in mandatory fields
    mandatoryFields.forEach((field) => {
      const fieldKeys = field.split(".");
      if (fieldKeys.length === 1) {
        if (!signUpData[fieldKeys[0]]) {
          isValid = false;
          console.log(`Missing Field: ${fieldKeys[0]}`);
        }
      } else if (fieldKeys.length === 2) {
        if (!signUpData[fieldKeys[0]][fieldKeys[1]]) {
          isValid = false;
          console.log(`Missing Field: ${fieldKeys[1]} in ${fieldKeys[0]}`);
        }
      }
    });

    setErrorData(newErrorData);
    setShowErrorMessage(!isValid);
    console.log("isValid: ", isValid);
    console.log("newErrorData: ", newErrorData);

    if (isValid) {
      const createNursePayload = {
        nurse: {
          first_name: signUpData?.first_name,
          last_name: signUpData?.last_name,
          email: signUpData?.email,
          phone_number: signUpData?.phone_number,
          date_of_birth: signUpData?.date_of_birth,
          qualification: signUpData?.qualification,
          languages_known: signUpData?.languages,
          education_certificate: signUpData?.educationCertificate, // Non-mandatory
          profile_picture: signUpData?.profilePhoto, // Non-mandatory
          user: {
            email: signUpData?.email,
            phone: signUpData?.phone_number,
          },
          bank_details: {
            name: signUpData?.bank_details.name,
            account_number: parseInt(signUpData?.bank_details.account_number),
            ifsc_code: signUpData?.bank_details.ifsc_code,
            bank_name: signUpData?.bank_details.bank_name, // Optional
          },
        },
      };
      console.log("createNursePayload: ", createNursePayload);

      const res = (await dispatch(addNurseAPI(createNursePayload))) as any;
      if (res?.error) {
        toast.error(res?.error?.message || "Unknown Error Occurred");
        // handleClosePopup();
        return;
      } else {
        toast.success("Nurse Added Successfully");
      }
      handleClosePopup();
    }
  };

  const handleClosePopup = () => {
    props?.handleCancel();
  };

  const uploadImage = (imgUrl: string) => {
    if (uploadField) {
      setSignUpData((prevData: any) => ({
        ...prevData,
        [uploadField]: imgUrl,
      }));
    }
    setShowImgModal(false);
    setUploadField(null);
  };

  const openImageUploadModal = (field: string) => {
    setUploadField(field);
    setShowImgModal(true);
  };

  return (
    <NursesStyled>
      <div className="create-new-institute-sec">
        <div className="create-new-institute-sec-content">
          <div className="student-info-values">
            <h3>Add New Nurse</h3>
            <Row>
              <Col>
                <Form.Group controlId="first_name">
                  <Form.Label>
                    First Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={errorData.first_name}
                    name="first_name"
                    type="text"
                    value={signUpData?.first_name}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter the first name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="last_name">
                  <Form.Label>
                    Last Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={errorData.last_name}
                    name="last_name"
                    type="text"
                    value={signUpData?.last_name}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter the last name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="email">
                  <Form.Label>
                    Email <span>*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={errorData.email}
                    name="email"
                    type="email"
                    value={signUpData?.email}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="phone_number">
                  <Form.Label>
                    Phone Number <span>*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={errorData.phone_number}
                    name="phone_number"
                    type="text"
                    value={signUpData?.phone_number}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only digits and limit input to 10 characters
                      if (/^\d*$/.test(value) && value.length <= 10) {
                        handleInputChange(e);
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter the phone number.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="bank_details.name">
                  <Form.Label>
                    Account Holder Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={errorData.bank_details.name}
                    name="bank_details.name"
                    type="text"
                    value={signUpData?.bank_details.name}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter the account holder's name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="bank_details.account_number">
                  <Form.Label>
                    Account Number <span>*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={errorData.bank_details.account_number}
                    name="bank_details.account_number"
                    type="text"
                    value={signUpData?.bank_details.account_number}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only digits and limit input to 10 characters
                      if (/^\d*$/.test(value) && value.length <= 14) {
                        handleInputChange(e);
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter the account number.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="bank_details.ifsc_code">
                  <Form.Label>
                    IFSC Code <span>*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={errorData.bank_details.ifsc_code}
                    name="bank_details.ifsc_code"
                    type="text"
                    value={signUpData?.bank_details.ifsc_code}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter the IFSC code.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="bank_details.bank_name">
                  <Form.Label>
                    Bank Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={errorData.bank_details.bank_name}
                    name="bank_details.bank_name"
                    type="text"
                    value={signUpData?.bank_details.bank_name}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter the bank name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Row>
                  <Col>
                    <Form.Group controlId="date_of_birth">
                      <Form.Label>Date of Birth</Form.Label>
                      <Form.Control
                        name="date_of_birth"
                        type="date"
                        value={signUpData?.date_of_birth}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Group controlId="qualification">
                  <Form.Label>Qualification</Form.Label>
                  <Form.Control
                    name="qualification"
                    type="text"
                    value={signUpData?.qualification}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="languages">
                  <Form.Label>
                    Languages <span>*</span>
                  </Form.Label>
                  <Form.Control
                    name="languages"
                    isInvalid={errorData.languages}
                    type="text"
                    value={signUpData?.languages}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter the Laguages.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="address">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    name="address"
                    type="text"
                    value={signUpData?.address}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group
                  controlId="educationCertificate"
                  className="d-flex flex-col gap-2 w-fit"
                >
                  <Form.Label>Education Certificate</Form.Label>
                  <div className="">
                    <Button
                      style={{ cursor: "pointer" }}
                      variant="outline-primary"
                      onClick={() =>
                        openImageUploadModal("educationCertificate")
                      }
                    >
                      {signUpData?.educationCertificate
                        ? "Certificate Uploaded"
                        : "Upload Education Certificate"}
                    </Button>
                    {signUpData?.educationCertificate && (
                      <img
                        width={200}
                        src={signUpData?.educationCertificate}
                        alt="Certificate"
                      />
                    )}
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  controlId="profilePhoto"
                  className="d-flex flex-col gap-2 w-fit"
                >
                  <Form.Label>Profile Photo</Form.Label>
                  <div className="">
                    <Button
                      style={{ cursor: "pointer" }}
                      variant="outline-primary"
                      onClick={() => openImageUploadModal("profilePhoto")}
                    >
                      {signUpData?.profilePhoto
                        ? "Photo Uploaded"
                        : "Upload Profile Photo"}
                    </Button>
                    {signUpData?.profilePhoto && (
                      <img
                        width={200}
                        src={signUpData?.profilePhoto}
                        alt="Profile Photo"
                      />
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
        <div className="actions-btn-sec align-center d-flex">
          <Button
            className="deltape-cancel-button"
            variant="outline-primary"
            onClick={handleClosePopup}
          >
            Cancel
          </Button>
          <Button className="deltape-button" onClick={handleSaveClick}>
            Create Nurse Profile
          </Button>
        </div>
      </div>

      <ModalImageUpload
        handleModalClose={() => setShowImgModal(false)}
        handleUploadSuccess={uploadImage}
        modalShow={showImgModal}
      />
    </NursesStyled>
  );
};

export default CreateUser;
