import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { IndexStyled } from "./index.styled";

import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Axios from "axios";
import image_arrow from "./../../images/img/Vector(1).png";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import clsx from "clsx";
import CloseButton from "react-bootstrap/CloseButton";
const BreadCrumb = (props: any) => {
  console.log("propsprops", props);
  const { list } = props;
  const history = useHistory();
  const [isError, setIsError] = useState("");
  const dispatch = useDispatch();
  const [color, setColor] = useState("rgb(255, 255, 255)");
  const [miles, setMiles] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [isLoding, setIsLoding] = useState(false);
  const gotoNewPage = (url: any) => {
    history.push(url);
  };
  // console.log("signUpData final", JSON.stringify(signUpData));
  return (
    <>
      <IndexStyled>
        <div className="breadcrumb1-row">
          <span
            className="line-item-text"
            onClick={() => {
              gotoNewPage("/dashboard");
            }}
          >
            Home
          </span>

          {list?.map((item: any) => {
            return (
              <>
                <span className="line-item-dd">
                  {" "}
                  <img src={image_arrow} />{" "}
                </span>
                <span
                  className="line-item-text"
                  onClick={() => {
                    gotoNewPage(item?.link);
                  }}
                >
                  {item?.title}
                </span>
              </>
            );
          })}
        </div>
      </IndexStyled>
    </>
  );
};
export default BreadCrumb;
