import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {} from "./dashboardService";

export interface DashboardState {
  loading: boolean;
  error: any;
}
const initialState: DashboardState = {
  loading: false,
  error: null,
};

export const dashboardSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state: DashboardState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateErrorMessage: (
      state: DashboardState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    //getAllPricelineSales
  },
});

export const { setLoading, updateErrorMessage } = dashboardSlice.actions;

export default dashboardSlice.reducer;
