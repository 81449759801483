import { useEffect, useState } from "react";
import { NursesStyled } from "./Nurses.styled";
import { Table, Pagination, Dropdown, Menu } from "antd";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddNewUserModel from "./AddNewNursesModel";
import Loader from "../../components/Common/Loader";
import Select from "react-select";
import add_icon from "../../images/img/add.png";
import add_icon1 from "../../images/img/download-icon.png";

import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import Switch from "react-switch";
import {
  deleteNurseAPI,
  getAllNursesAPI,
  toggleStatusAPI,
  updateNurseAPI,
} from "../../redux/slices/nurses/nursesService";
import type { ColumnsType } from "antd/es/table";
import { BiDotsVerticalRounded } from "react-icons/bi";

import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

const EditNurseModal = ({ show, onHide, nurseData, onSave }: any) => {
  const [formData, setFormData] = useState(nurseData);

  useEffect(() => {
    setFormData(nurseData);
  }, [nurseData]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    onSave(formData);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Nurse</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group controlId="first_name">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="first_name"
                  value={formData?.first_name || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="last_name">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="last_name"
                  value={formData?.last_name || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData?.email || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="phone_number">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  name="phone_number"
                  value={formData?.phone_number || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="gender">
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  value={formData?.gender || ""}
                  onChange={handleChange}
                >
                  <option>Male</option>
                  <option>Female</option>
                  <option>Other</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="date_of_birth">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  name="date_of_birth"
                  value={formData?.date_of_birth?.slice(0, 10) || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="consultation_fee">
                <Form.Label>Consultation Fee</Form.Label>
                <Form.Control
                  type="number"
                  name="consultation_fee"
                  value={formData?.consultation_fee || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="specialization">
                <Form.Label>Specialization</Form.Label>
                <Form.Control
                  type="text"
                  name="specialization"
                  value={formData?.specialization || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="check_in_time">
                <Form.Label>Check-in Time</Form.Label>
                <Form.Control
                  type="time"
                  name="check_in_time"
                  value={formData?.check_in_time || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="check_out_time">
                <Form.Label>Check-out Time</Form.Label>
                <Form.Control
                  type="time"
                  name="check_out_time"
                  value={formData?.check_out_time || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form.Group controlId="address_line_1">
                <Form.Label>Address Line 1</Form.Label>
                <Form.Control
                  type="text"
                  name="address_line_1"
                  value={formData?.address_line_1 || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="address_line_2">
                <Form.Label>Address Line 2</Form.Label>
                <Form.Control
                  type="text"
                  name="address_line_2"
                  value={formData?.address_line_2 || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={formData?.city || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  name="state"
                  value={formData?.state || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="pincode">
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  type="text"
                  name="pincode"
                  value={formData?.pincode || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="country">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  name="country"
                  value={formData?.country || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="license_number">
                <Form.Label>License Number</Form.Label>
                <Form.Control
                  type="text"
                  name="license_number"
                  value={formData?.license_number || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="qualification">
                <Form.Label>Qualification</Form.Label>
                <Form.Control
                  type="text"
                  name="qualification"
                  value={formData?.qualification || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="years_of_experience">
                <Form.Label>Years of Experience</Form.Label>
                <Form.Control
                  type="number"
                  name="years_of_experience"
                  value={formData?.years_of_experience || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="languages_known">
                <Form.Label>Languages Known</Form.Label>
                <Form.Control
                  type="text"
                  name="languages_known"
                  value={formData?.languages_known || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="status">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  value={formData?.status || ""}
                  onChange={handleChange}
                >
                  <option>active</option>
                  <option>inactive</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ManageNurses = () => {
  const [showAddNewUserModel, setShowAddNewUserModel] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState({
    value: "All",
    label: "All",
  });
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [nursesData, setNursesData] = useState<any>([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [showEditModal, setShowEditModal] = useState(false);
  const [editNurseData, setEditNurseData] = useState<any>(null);
  const [deleteNurseData, setDeleteNurseData] = useState<any>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { nursesList: allNursesData } = useSelector(
    (state: any) => state?.nurses
  );
  const [nursesList, setNursesList] = useState([]);

  useEffect(() => {
    setNursesList(
      allNursesData?.filter((nurse: any) => nurse?.status !== "deleted") || []
    );
  }, [allNursesData]);

  useEffect(() => {
    setNursesData(
      nursesList.map((nurse: any) => ({
        ...nurse,
        status: nurse?.status === "approved" ? nurse?.status : "inactive",
      }))
    );
  }, [nursesList]);

  useEffect(() => {
    getAllUsersCall();
  }, []);

  const getAllUsersCall = async () => {
    setIsLoading(true);
    await dispatch(getAllNursesAPI());
    setIsLoading(false);
  };
  const handlePageChange = (val: any, val1: any) => {
    setPageSize(val1);
    setPageNo(val);
  };
  const handleCancel = () => {
    setShowAddNewUserModel(false);
  };
  const addUserSuccess = () => {
    setShowAddNewUserModel(false);
    setShowSuccessMessage(true);
  };

  const handleChangeValue = (e: any) => {
    setSearchText(e.target.value);
    setPageNo(0);
  };

  const handleChangeRole1 = (selectedOption: any) => {
    setPageNo(0);
    setSelectedStatus(selectedOption);
  };

  useEffect(() => {
    console.log("selectedStatus : ", selectedStatus);
  }, [selectedStatus]);

  const filteredNurses = nursesData.filter((nurse: any) => {
    const searchMatch =
      nurse?.first_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      nurse?.last_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      nurse?.email?.toLowerCase().includes(searchText.toLowerCase()) ||
      nurse?.phone_number?.includes(searchText);

    const statusMatch =
      selectedStatus.value === "All" || nurse?.status === selectedStatus.value;

    return searchMatch && statusMatch;
  });

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleStatusToggle = async (id: any) => {
    // Find the current status of the nurse
    const currentStatus = nursesData.find(
      (nurse: any) => nurse.id === id
    )?.status;

    // Determine the new status based on the current status
    const newStatus = currentStatus === "approved" ? "inactive" : "approved";

    const toggleBody = {
      id,
      payload: {
        status: newStatus,
      },
    };

    const res = (await dispatch(toggleStatusAPI(toggleBody))) as any;
    console.log("res", res);
    if (res?.error) {
      toast.error(res?.error?.message || "Unknown Error Occured");
      return;
    } else {
      toast.success("Status updated successfully");
    }

    // Update the nurse data locally
    setNursesData((prevData: any) =>
      prevData.map((nurse: any) =>
        nurse.id === id
          ? {
              ...nurse,
              status: newStatus,
            }
          : nurse
      )
    );
  };

  const handleCreatenewApp = () => {
    setSelectedUser("");
    setShowAddNewUserModel(true);
  };

  const columns: ColumnsType<any> = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "First Name", dataIndex: "first_name", key: "first_name" },
    { title: "Last Name", dataIndex: "last_name", key: "last_name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone Number", dataIndex: "phone_number", key: "phone_number" },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (text) => text || "N/A",
    },
    {
      title: "Date of Birth",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      render: (text) => (text ? new Date(text).toLocaleDateString() : "N/A"),
    },
    {
      title: "Consultation Fee",
      dataIndex: "consultation_fee",
      key: "consultation_fee",
      render: (text) => (text ? `$${text}` : "N/A"),
    },
    {
      title: "Check-In Time",
      dataIndex: "check_in_time",
      key: "check_in_time",
    },
    {
      title: "Check-Out Time",
      dataIndex: "check_out_time",
      key: "check_out_time",
    },
    {
      title: "Specialization",
      dataIndex: "specialization",
      key: "specialization",
      render: (text) => text || "N/A",
    },
    {
      title: "Address",
      key: "address",
      render: (_, record) =>
        `${record.address_line_1 || ""} ${record.address_line_2 || ""}, ${
          record.city || ""
        }, ${record.state || ""}, ${record.country || ""} ${
          record.pincode || ""
        }`,
    },
    {
      title: "License Number",
      dataIndex: "license_number",
      key: "license_number",
      render: (text) => text || "N/A",
    },
    {
      title: "Qualification",
      dataIndex: "qualification",
      key: "qualification",
      render: (text) => text || "N/A",
    },
    {
      title: "Languages Known",
      dataIndex: "languages_known",
      key: "languages_known",
      render: (text) => text || "N/A",
    },
    {
      title: "Years of Experience",
      dataIndex: "years_of_experience",
      key: "years_of_experience",
      render: (text) => text || "N/A",
    },
    {
      title: "Status",
      key: "status",
      fixed: "right",
      render: (_, record) => (
        <Switch
          checked={record?.status === "approved"}
          onChange={() => handleStatusToggle(record.id)}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="edit" onClick={() => handleEdit(record)}>
                Edit
              </Menu.Item>
              <Menu.Item
                key="delete"
                onClick={() => {
                  setDeleteNurseData(record);
                  setShowDeleteModal(true);
                }}
              >
                Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <BiDotsVerticalRounded
            style={{ fontSize: "20px", cursor: "pointer" }}
          />
        </Dropdown>
      ),
    },
  ];

  const handleEdit = (record: any) => {
    console.log("record : ", record);

    setEditNurseData(record);
    setShowEditModal(true);
  };

  const handleSaveChanges = async (updatedData: any) => {
    console.log("Saved Nurse Data:", updatedData);
    const updateBody = {
      id: updatedData?.id,
      payload: {
        nurse: {
          first_name: updatedData?.first_name || null,
          last_name: updatedData?.last_name || null,
          email: updatedData?.email || null,
          phone_number: updatedData?.phone_number || null,
          gender: updatedData?.gender?.toLowerCase() || null,
          date_of_birth: updatedData?.date_of_birth || null,
          consultation_fee: updatedData?.consultation_fee || 0,
          check_in_time: updatedData?.check_in_time || null,
          check_out_time: updatedData?.check_out_time || null,
          specialization: updatedData?.specialization || null,
          address_line_1: updatedData?.address_line_1 || null,
          address_line_2: updatedData?.address_line_2 || null,
          city: updatedData?.city || null,
          state: updatedData?.state || null,
          pincode: updatedData?.pincode || null,
          country: updatedData?.country || null,
          license_number: updatedData?.license_number || null,
          qualification: updatedData?.qualification || null,
          languages_known: updatedData?.languages_known || null,
          years_of_experience: updatedData?.years_of_experience || 0,
          user: {
            email: updatedData?.email || null,
            phone: updatedData?.phone_number || null,
          },
          bank_details: {
            id: updatedData?.id || 0,
            name: `${updatedData?.first_name || null} ${
              updatedData?.last_name || null
            }`,
          },
        },
      },
    };
    const res = (await dispatch(updateNurseAPI(updateBody))) as any;
    console.log("res : ", res);

    if (res?.error) {
      toast.error(res?.error?.message || "Unknown Error Occured");
      setShowEditModal(false);
      return;
    } else {
      toast.success("Nurse Data Updated Successfully");
      await dispatch(getAllNursesAPI());
    }
    setShowEditModal(false);
    // Update the nurses list with the updated data if needed
  };

  const handleDelete = async () => {
    // Logic to handle deletion
    console.log("Delete nurse:", deleteNurseData?.id);
    const res = (await dispatch(deleteNurseAPI(deleteNurseData?.id))) as any;
    console.log("res : ", res);

    if (res?.error) {
      toast.error(res?.error?.message || "Unknown Error Occured");
      setShowDeleteModal(false);
      return;
    } else {
      toast.success("Nurse Deleted Successfully");
      await dispatch(getAllNursesAPI());
      setShowDeleteModal(false);
      return;
    }
  };

  const rolesList1 = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Active",
      value: "approved",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
  ] as any;
  return (
    <>
      <NursesStyled>
        <div className="freshbag-wrapper">
          {isLoading && <Loader />}
          <div className="content getinTouchPage">
            <div>
              <BreadCrumb list={[{ title: "Nurses" }]} />
              <div className="profileinfoHeader">
                <div className="top-sec-header-sec">
                  <span className="edit-p-text">Manage Nurses</span>
                  <div onClick={handleCreatenewApp}>
                    <span className="profileEdit">
                      Add New Nurse &nbsp; <img src={add_icon} />
                    </span>
                  </div>
                </div>
                <p>View All Nurses</p>
              </div>
            </div>
            <div className="create-new-institute-sec-content-all">
              <div className="student-fields-sec-content-all deltape-form">
                <div className="student-info-row">
                  <Form.Group className="delta-signup-md" controlId="email">
                    <Form.Label> Search</Form.Label>
                    <Form.Control
                      placeholder="Search: Name, Email, Phone"
                      // autoFocus
                      // isInvalid={errorData?.studentName}
                      name="studentName"
                      type="text"
                      value={searchText}
                      onChange={(e) => handleChangeValue(e)}
                    />
                  </Form.Group>
                </div>

                <div className="delta-select-column">
                  <label>Select Status </label>
                  <Select
                    value={selectedStatus?.value ? selectedStatus : null}
                    placeholder="Select Role"
                    onChange={handleChangeRole1}
                    options={rolesList1}
                    className="delta-select"
                    classNamePrefix="delta-select"
                  />
                </div>
                <div className="download-icon-sec">
                  <img src={add_icon1} />
                </div>
                {/* <div className="delta-select-column submit-filter-btn-sec">
                  <button className="deltape-button">SUBMIT</button>
                </div> */}
              </div>
            </div>
            <div>
              <div className="total-count-row"></div>
              <div className="institutes-filters"></div>
              <div className="all-institutes-data rapha-table-view">
                <Table
                  columns={columns}
                  dataSource={filteredNurses.slice(
                    (currentPage - 1) * pageSize,
                    currentPage * pageSize
                  )}
                  rowKey="id"
                  pagination={{
                    current: currentPage,
                    pageSize,
                    total: filteredNurses.length,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "50", "100"],
                  }}
                  onChange={handleTableChange}
                  bordered
                  scroll={{ x: "max-content" }}
                />

                <div className="rapha-table-pagination">
                  <div>
                    <span>
                      Showing {pageSize} of {nursesList?.length} Users
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {nursesList?.length === 0 && (
            <div className="no-results">
              <p>No Users available</p>
            </div>
          )}
          <AddNewUserModel
            handleCancel={handleCancel}
            selectedUser={selectedUser}
            addUserSuccess={addUserSuccess}
            show={showAddNewUserModel}
            getAllUsersCall={getAllUsersCall}
          />

          <EditNurseModal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            nurseData={editNurseData}
            onSave={handleSaveChanges}
          />

          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this Nurse? This action cannot be
              undone.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
              <Button variant="danger" onClick={handleDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </NursesStyled>
    </>
  );
};

export default ManageNurses;
