import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllDoctors } from "./doctorsService";

export interface ExtensionDashboardState {
  loading: boolean;
  error: any;
  doctorsList: any;
}
const userData: string | null = localStorage.getItem("extensionUser");

const initialState: ExtensionDashboardState = {
  loading: false,
  error: null,
  doctorsList: userData !== null ? JSON.parse(userData) : {},
  // dailyRevenueInfo: [],
};

export const dashboardSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (
      state: ExtensionDashboardState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },

    updateErrorMessage: (
      state: ExtensionDashboardState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getAllDoctors.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllDoctors.fulfilled,
      (state: ExtensionDashboardState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.doctorsList = action.payload?.data;
      }
    );
    builder.addCase(
      getAllDoctors.rejected,
      (state: ExtensionDashboardState, action: any) => {
        state.loading = false;
      }
    );
  },
});

export const { setLoading, updateErrorMessage } = dashboardSlice.actions;

export default dashboardSlice.reducer;
