import { Col, Row } from "react-bootstrap";

const Filter = ({ isChecked, handleCheckboxChange }: any) => {
  return (
    <>
      <Row className="filter_btn_wrp_row">
        <Col md={3} className="filter_btn_wrp">
          <button
            className={`checkbox-button ${
              isChecked === "Yesterday" ? "checked" : ""
            }`}
            onClick={() => handleCheckboxChange("Yesterday")}
          >
            Yesterday
          </button>
        </Col>
        <Col md={3} className="filter_btn_wrp">
          <button
            className={`checkbox-button ${
              isChecked === "Lastweek" ? "checked" : ""
            }`}
            onClick={() => handleCheckboxChange("Lastweek")}
          >
            Last week
          </button>
        </Col>
        <Col md={3} className="filter_btn_wrp">
          <button
            className={`checkbox-button ${
              isChecked === "LastMonth" ? "checked" : ""
            }`}
            onClick={() => handleCheckboxChange("LastMonth")}
          >
            Last Month
          </button>
        </Col>
        <Col md={3} className="filter_btn_wrp">
          <button
            className={`checkbox-button ${
              isChecked === "Last7days" ? "checked" : ""
            }`}
            onClick={() => handleCheckboxChange("Last7days")}
          >
            Last 7 days
          </button>
        </Col>
        <Col md={3} className="filter_btn_wrp">
          <button
            className={`checkbox-button ${
              isChecked === "Last30days" ? "checked" : ""
            }`}
            onClick={() => handleCheckboxChange("Last30days")}
          >
            Last 30 days
          </button>
        </Col>
        <Col md={3} className="filter_btn_wrp">
          <button
            className={`checkbox-button ${
              isChecked === "Last90days" ? "checked" : ""
            }`}
            onClick={() => handleCheckboxChange("Last90days")}
          >
            Last 90 days
          </button>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
