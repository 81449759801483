import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, useLocation } from "react-router-dom";
import * as ReactPopOver from "react-popover";
import { isMobile } from "react-device-detect";

import Logo from "../../img/SocialStarhub.png";
// import logo1 from "../../images/Redclub..png";
import search1 from "../../images/search.png";
import hearticon from "../../images/ph_heart-straight.png";
import notificationicon from "../../images/clarity_notification-line.png";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { HeaderStyled } from "./Header.styled";
import { Row, Col, Button, CardDeck, CardColumns } from "react-bootstrap";
import "./styles.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import DropdownList from "./DropdownList";
import { updateUserDetails } from "../../redux/slices/auth/authSlice";
// import {ReactComponent as ProjLogo} from "../../images/Redclub.svg"
import logo1 from "../../images/img/logo_design_1.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Header = (props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector(({ auth }) => auth);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {}, []);
  const gotoCartPage = (url) => {
    setIsOpen(false);
    history.push(url);
  };
  const getShortName = () => {
    var shortname = "";
    try {
      var firstName = user?.data?.firstName
        ? user?.data?.firstName.charAt(0).toUpperCase()
        : "";
      var lastName = user?.data?.lastName
        ? user?.data?.lastName.charAt(0).toUpperCase()
        : "";
      shortname = firstName + lastName;
    } catch (error) {}
    return shortname;
  };
  const togglePopover = () => {
    setIsOpen(!isOpen);
  };
  const handleLogout = () => {
    localStorage.clear();
    // history.push("/signin");
    // setIsOpen(!isOpen);
    window.location.href = "/signin";
    // dispatch(updateUserDetails(null));
  };

  const handleMoreItemClick = (name, to) => {};
  return (
    <>
      <HeaderStyled>
        <ToastContainer />
        <div className="header-wrapper" id="headerWrapper">
          <div className="headerSection">
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="allItemsSection">
                <div className="row-item-secion">
                  <span
                    onClick={() => {
                      gotoCartPage("/");
                    }}
                  >
                    {" "}
                    <img className="row-item-secion-img-sec" src={logo1} />
                  </span>
                </div>

                <div
                  className="icons-sec-box cursor-pointer row-item-secion"
                  onClick={() => {
                    gotoCartPage("/dashboard");
                  }}
                >
                  {/* <span className="profile-logo">
                  <div>JD</div>
                  </span> */}
                  {/* <span>
                    <img src={logo1} />{" "}
                  </span> */}
                </div>

                <div className="rightsideItem1">
                  <span className="signInButtonArea right-side-icons-sec">
                    {user?.id ? (
                      <>
                        <div>
                          <ReactPopOver
                            isOpen={isOpen}
                            body={
                              <DropdownList
                                handleLogout={handleLogout}
                                gotoNextPage={gotoCartPage}
                              />
                            }
                            place="below"
                            onOuterAction={togglePopover}
                          >
                            <span
                              className="btn-user-profile"
                              // onClick={togglePopover}
                            >
                              <div className="profileIconStyles">
                                <span className="signed-user-name">
                                  {`${user?.first_name} ${user?.last_name}`}
                                </span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#008080"
                                  class="bi bi-person-circle"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                                  />
                                </svg>
                              </div>
                            </span>
                          </ReactPopOver>
                        </div>
                        <div>
                          <span
                            className="log-out-btn-header"
                            onClick={handleLogout}
                          >
                            {" "}
                            Log out
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => {
                            gotoCartPage("/signIn");
                          }}
                          className="signInButton1"
                        >
                          Log In
                        </button>
                      </>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="sub-header-sec">
          <div className="sub-header-sec-content">
            <span className="sub-header-text">Top Deals</span>
            <span className="vertical-line"></span>
            <span className="sub-header-text">Redclub Coins</span>
            <span className="vertical-line"></span>
            <span className="sub-header-text">
              Your Earnings: <span className="sub-heaser-doller">$0</span>
            </span>
            <span className="vertical-line"></span>
            <span className="sub-header-text">How it works?</span>
            <span className="vertical-line"></span>
            <span className="sub-header-text">Signin</span>
          </div>
        </div> */}
      </HeaderStyled>
    </>
  );
};

export default Header;
