import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../pages/Home";
import Signin from "../pages/Signin";
import SignUp from "../pages/Signin/SignUp";
import MyProfile from "../pages/MyProfile";
import Dashboard from "../pages/Dashboard";
import Settings from "../pages/Settings";
import MyProfileInfo from "../pages/MyProfileInfo";
import HandleClick from "../pages/HandleClick/HandleClick";
import { useLocation } from "react-router-dom";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import ManageDoctors from "./../pages/ManageDoctors/ManageDoctors";
import ManageBookings from "./../pages/ManageBookings/ManageBookings";

import ManageNurses from "../pages/Nurses/ManageNurses";
import ManageClients from "../pages/ManageClients/ManageClients";
import ManageUsers from "../pages/ManageUsers/ManageUsers";

const MainRoutes: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      {/*Route exact path="/" component={Home} />*/}

      <Route exact path="/" component={Dashboard} />
      <Route exact path="/home" component={Dashboard} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/Doctors" component={ManageDoctors} />
      <Route exact path="/users" component={ManageUsers} />
      <Route exact path="/clients" component={ManageClients} />
      <Route exact path="/Bookings" component={ManageBookings} />

      <Route exact path="/signin" component={Signin} />
      <Route exact path="/signup_vinod_0599" component={SignUp} />
      <Route exact path="/myProfile" component={MyProfile} />
      <Route exact path="/Settings" component={Settings} />
      <Route exact path="/MyProfileInfo" component={MyProfileInfo} />
      <Route exact path="/handleclick" component={HandleClick} />
      <Route exact path="/nurses" component={ManageNurses} />
      <Route exact path="/changePassword" component={ChangePassword} />
    </Switch>
  );
};

export default MainRoutes;
