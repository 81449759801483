import { configureStore } from "@reduxjs/toolkit";

// reducers
import appReducer from "../slices/app/appSlice";
import dashboardReducer from "../slices/dashboard/dashboardSlice";
import authReducer from "../slices/auth/authSlice";
import doctorReducer from "../slices/doctors/doctorsSlice";
import nursesReducer from "../slices/nurses/nursesSlice";
import reportsReducer from "../slices/reports/reportsSlice";
import UsersReducer from "../slices/UserManagement/UserManagementSlice";
import ClientsReducer from "../slices/Clients/ClientsSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    doctors: doctorReducer,
    nurses: nursesReducer,
    reports: reportsReducer,
    userManagement: UsersReducer,
    clients: ClientsReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
