import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";
import { getAllDoctorsQuery } from "../../../Scenes/graphqlConfig";

export const getAllNursesAPI = createAsyncThunk(
  "nurse/getAllNursesAPI",
  async () => await get(`${SERVER_IP}/api/v1/nurse`)
);

export const addNurseAPI = createAsyncThunk(
  "nurse/addNurseAPI",
  async (body: any) => await post(`${SERVER_IP}/api/v1/nurse`, body)
);

export const deleteNurseAPI = createAsyncThunk(
  "nurse/deleteNurseAPI",
  async (id: any) => await put(`${SERVER_IP}/api/v1/nurse/delete/${id}`)
);

export const updateNurseAPI = createAsyncThunk(
  "nurse/updateNurseAPI",
  async (body: any) =>
    await put(`${SERVER_IP}/api/v1/nurse/${body?.id}`, body?.payload)
);

export const toggleStatusAPI = createAsyncThunk(
  "nurse/toggleStatusAPI",
  async (body: any) =>
    await put(`${SERVER_IP}/api/v1/nurse/${body?.id}/toggle`, body?.payload)
);
