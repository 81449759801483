import styled from "styled-components";

export const CahngePasswordStyled = styled.div`
  .password_field {
    position: relative;
    .eyeIcon {
      position: absolute;
      right: 10px;
      top: 44px;
      cursor: pointer;
    }
  }
  .main_sec {
    border-radius: 16px;
    border: 1px solid #cfd3d7;
    background: #fcfcfc;
    padding: 25px;
    margin-right: 50px;
    input {
      height: 48px;
    }
    .btn_wrapper {
      display: flex;
      justify-content: end;
      button {
        display: flex;
        width: 325px;
        height: 52px;
        padding: 14px 32px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 18px;
        background: var(--Primary-Red, #008080);
        color: var(--Shade-0, #fff);

        /* Button Text/Large */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        letter-spacing: 0.032px;
        text-transform: uppercase;
        border: none;
        cursor: pointer;
      }
      button: disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
  .error {
    color: red;
  }
`;
