import { Col, Row } from "react-bootstrap";
import { EditProfileStyled } from "./EditProfile.styled";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import {
//   getUserDetailsById,
//   updateUserDetails,
// } from "../../redux/slices/auth/authService";

const EditProfile = ({ user, handleCancelEdit }: any) => {
  const [values, setValues]: any = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setValues({ ...user });
  }, [user]);

  const handleChangeValue = (field: string, value: string) => {
    setValues({ ...values, [field]: value });
  };

  const updateUserDetailsCall = async () => {
    setLoading(true);
    const payload = {
      ...values,
      _id: user?._id,
    };
    // await dispatch(updateUserDetails(payload));
    // dispatch(getUserDetailsById({ id: user?._id }));
    // setLoading(false);
    // handleCancelEdit();
  };
  return (
    <EditProfileStyled>
      <div className="main_wrapper">
        <h1>Edit Account Details</h1>
        <Row>
          <Col md={12} className="input_field">
            <label>Vendor ID</label>
            <input
              type="text"
              placeholder="Enetr Vendor ID"
              value={values?.vendorId}
              disabled
            ></input>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="input_field">
            <label>Vendor Name*</label>
            <input
              type="text"
              placeholder="Enter Vendor Name"
              value={values?.fullName}
              maxLength={60}
              onChange={(e: any) =>
                handleChangeValue("fullName", e.target.value)
              }
            ></input>
          </Col>
          <Col md={6} className="input_field">
            <label>Email Address*</label>
            <input
              type="text"
              placeholder="Enter Email Address"
              value={values?.email}
              disabled
            ></input>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="input_field">
            <label>Address Line 1</label>
            <input
              type="text"
              placeholder="Enter Address Line 1"
              value={values?.address1}
              onChange={(e: any) =>
                handleChangeValue("address1", e.target.value)
              }
            ></input>
          </Col>
          <Col md={6} className="input_field">
            <label>Address Line 2</label>
            <input
              type="text"
              placeholder="Enter Address Line 2"
              value={values?.address2}
              onChange={(e: any) =>
                handleChangeValue("address2", e.target.value)
              }
            ></input>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="input_field">
            <label>Country/Region</label>
            <input
              type="text"
              placeholder="Enter Country/Region"
              value={values?.country}
              onChange={(e: any) =>
                handleChangeValue("country", e.target.value)
              }
            ></input>
          </Col>
          <Col md={6} className="input_field">
            <label>State</label>
            <input
              type="text"
              placeholder="Enter State"
              value={values?.state}
              onChange={(e: any) => handleChangeValue("state", e.target.value)}
            ></input>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="input_field">
            <label>City</label>
            <input
              type="text"
              placeholder="Enter City"
              value={values?.city}
              onChange={(e: any) => handleChangeValue("city", e.target.value)}
            ></input>
          </Col>
          <Col md={6} className="input_field">
            <label>Zip Code</label>
            <input
              type="text"
              placeholder="Enter Zip code"
              value={values?.zip}
              maxLength={5}
              onChange={(e: any) => {
                const numericValue = e.target.value.replace(/\D/g, "");
                handleChangeValue("zip", numericValue);
              }}
            ></input>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="input_field">
            <label>Phone Number</label>
            <input
              type="text"
              placeholder="Enter Phone Number"
              value={values?.phoneNumber}
              maxLength={10}
              onChange={(e: any) => {
                const numericValue = e.target.value.replace(/\D/g, "");
                handleChangeValue("phoneNumber", numericValue);
              }}
            ></input>
          </Col>
        </Row>
      </div>
      <div className="action_btn_wrap">
        <Button className="cls_btn" onClick={handleCancelEdit}>
          Cancel
        </Button>
        <Button
          className="update_btn"
          onClick={updateUserDetailsCall}
          loading={loading}
          disabled={!values?.fullName}
        >
          Update
        </Button>
      </div>
    </EditProfileStyled>
  );
};

export default EditProfile;
