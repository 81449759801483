import React, { useState, useEffect } from "react";
import { SideMenuStyled } from "./SideMenu.styled";
import dashboardIcon from "../../images/dashboardBlack.svg";
import deepLinkIcon from "../../images/links.svg";
import marchents from "../../images/group2.svg";
import personIcon from "../../images/person.svg";
import logoutIcon from "../../images/logout.svg";
import WhiteDash from "../../images/whiteDashboard.svg";
import whiteLink from "../../images/whitelink.svg";
import whiteUsers from "../../images/whiteUsers.svg";
import whiteUser from "../../images/whiteUser.svg";
import whiteProduct from "../../images/whiteProduct.svg";
import whiteEdit from "../../images/whiteEdit.svg";
import whiteCategory from "../../images/whiteCategory.svg";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { updateUserDetails } from "./../../redux/slices/auth/authSlice";
import productIcon from "../../images/Product.svg";
import categoryIcon from "../../images/Categrory.svg";
import editIcon from "../../images/edit.svg";
import { useSelector } from "react-redux";
import { FaUserDoctor, FaUserNurse } from "react-icons/fa6";

const Sidebar = (props: any) => {
  const [sidebar, setSidebar] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((reduxState: any) => reduxState.auth);
  const vendorId = user?.vendorId;

  const showSidebar = () => setSidebar(!sidebar);
  useEffect(() => {
    if (!user?.id) {
      history.push("/signin");
    }
  }, []);

  console.log("props", props);
  const logoutGotoNewPage = () => {
    localStorage.clear();
    dispatch(updateUserDetails(null));
    history.push("/signin");
  };
  const gotoNewPage = (url: any) => {
    if (url === "/createjobcard") {
      window.location.href = url;
    } else if (url) {
      history.push(url);
    }
  };
  const menuItems = [
    {
      title: "Dashboard",
      link: "dashboard",
      img: dashboardIcon,
      activeImg: WhiteDash,
    },

    {
      title: "My Account",
      link: "MyProfileInfo",
      img: personIcon,
      activeImg: whiteUser,
    },
    {
      title: "Bookings",
      link: "Bookings",
      img: <FaUserDoctor style={{ marginRight: "10px" }} />,
      activeImg: (
        <FaUserDoctor style={{ color: "white", marginRight: "10px" }} />
      ),
    },

    {
      title: "Change Password",
      link: "changePassword",
      img: editIcon,
      activeImg: whiteEdit,
    },
  ];

  const selectedUrl = window.location.pathname;
  console.log("selectedUrl", selectedUrl);
  return (
    <>
      <SideMenuStyled>
        <div className="menu-sec-add-list">
          {menuItems?.map((item: any, index: number) => {
            const isActive = selectedUrl === `/${item?.link}`;
            return (
              <div
                key={index}
                className={isActive ? "selected-link-item" : "row-link-item"}
              >
                <span
                  onClick={() => {
                    gotoNewPage(item?.link);
                  }}
                >
                  <span>
                    {typeof item.img === "string" ? (
                      <img
                        src={isActive ? item.activeImg : item.img}
                        alt={item.title}
                      />
                    ) : isActive ? (
                      item.activeImg
                    ) : (
                      item.img
                    )}
                  </span>
                  {item?.title}
                </span>
              </div>
            );
          })}
          <div className={"row-link-item row-link-item-logout"}>
            <span
              onClick={() => {
                logoutGotoNewPage();
              }}
            >
              <img src={logoutIcon} alt="logout" />
              Log out
            </span>
          </div>
        </div>
      </SideMenuStyled>
    </>
  );
};

export default Sidebar;
