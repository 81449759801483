import { Form, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { CahngePasswordStyled } from "./ChangePassword.styled";
import { useState } from "react";
import eyeIcon from "../../images/eyeClose.svg";
import { useHistory } from "react-router-dom";
import "./ChangePassword.css";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails } from "../../redux/slices/auth/authSlice";
import { changePasswordAPi } from "../../redux/slices/auth/authService";
import { Button } from "antd";
import openEyeIcon from "../../images/openEye.svg";

const ChangePassword = () => {
  const { user } = useSelector(({ auth }: any) => auth);

  const [showPass, setShowPass] = useState(false);
  const [values, setValues]: any = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [changeError, setChangeError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleValueChange = (type: string, value: string) => {
    setValues({ ...values, [type]: value });
    if (changeError) {
      setChangeError("");
    }
  };

  const handleOk = async () => {
    localStorage.clear();
    dispatch(updateUserDetails(null));
    history.push("/signin");
  };

  const handleChangePassword = async () => {
    setLoading(true);
    const res: any = await dispatch(
      changePasswordAPi({
        _id: user?._id,
        currentPassword: values?.currentPassword,
        newPassword: values?.newPassword,
      })
    );
    console.log("Res", res);
    if (Object.keys(res?.error || {})?.length > 0 && res?.error) {
      setChangeError(res?.error?.message);
    } else {
      setSuccessModal(true);
    }
    setLoading(false);
  };

  return (
    <CahngePasswordStyled>
      <h3>Change Password</h3>
      <div className="main_sec">
        <Form.Group
          className="signup-md1 password_field"
          controlId="old_password"
        >
          <Form.Label className="mb-1">Old Password*</Form.Label>

          <Form.Control
            type={"password"}
            name="password"
            placeholder="Password"
            // isInvalid={errorData?.password}
            value={values?.currentPassword}
            onChange={(e) =>
              handleValueChange("currentPassword", e?.target?.value)
            }
          />
          <Form.Control.Feedback type="invalid">
            {/* {errorData?.password ? <>{errorDataValue?.password}</> : null} */}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="signup-md1 password_field" controlId="password">
          <Form.Label className="mb-1">New Password*</Form.Label>
          {showPass ? (
            <img
              src={eyeIcon}
              alt="eye"
              className="eyeIcon"
              onClick={() => setShowPass(!showPass)}
            />
          ) : (
            <img
              src={openEyeIcon}
              alt="open-eye"
              className="eyeIcon"
              onClick={() => setShowPass(!showPass)}
            />
          )}
          <Form.Control
            type={showPass ? "text" : "password"}
            name="password"
            placeholder="Password"
            // isInvalid={errorData?.password}
            value={values?.newPassword}
            onChange={(e) => handleValueChange("newPassword", e?.target?.value)}
          />
          <Form.Control.Feedback type="invalid">
            {/* {errorData?.password ? <>{errorDataValue?.password}</> : null} */}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="signup-md1-left" controlId="confirm_password">
          <Form.Label className="mb-1">Confirm Password*</Form.Label>
          <Form.Control
            type="password"
            name="conformPassword"
            placeholder="Confirm Password"
            value={values?.conformPassword}
            onChange={(e) =>
              handleValueChange("conformNewPassword", e?.target?.value)
            }
          />
          {/* {passError && (
              <p className="errorMessage errorMessage-signup">{passError}</p>
            )} */}
        </Form.Group>
        {changeError && <div className="error">{changeError}</div>}
        <div className="btn_wrapper">
          <Button
            disabled={
              values?.newPassword !== values?.conformNewPassword ||
              !values?.currentPassword ||
              !values?.newPassword ||
              !values?.conformNewPassword
            }
            onClick={handleChangePassword}
            loading={loading}
          >
            Change Password
          </Button>
        </div>
      </div>
      <Modal show={successModal} centered className="pass_succes_pop">
        <ModalBody>Password Changed successfully</ModalBody>
        <ModalFooter>
          <Button onClick={handleOk}>Log in</Button>
        </ModalFooter>
      </Modal>
    </CahngePasswordStyled>
  );
};

export default ChangePassword;
