import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

export const getClicksReportsAPI = createAsyncThunk(
  "dashboard/getClicksReportsAPI",
  async (body: any) =>
    await get(
      `${SERVER_IP}/api/v1/dashboard?startDate=${body?.startDate}&endDate=${body?.endDate}`
    )
);
