export const API_BASE = "/api";
export const GRAPH_BASE = "https://graph.facebook.com/v6.0";
const hostName = window.location.hostname;

let SERVER_IP_URL;

SERVER_IP_URL = "https://api.raphacure.co.in";
// SERVER_IP_URL = "http://localhost:4000";
SERVER_IP_URL = "https://api.raphacure.com";
if (typeof window !== "undefined") {
  if (
    (hostName.indexOf("admin1.raphacure.com") !== -1 &&
      hostName.indexOf("staging.admin1.raphacure.com") === -1) ||
    hostName === "localhost"
  ) {
    SERVER_IP_URL = "https://api.raphacure.com";
  }
}

export const SERVER_IP = SERVER_IP_URL;
