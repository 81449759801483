import styled from "styled-components";

export const DashboardStyled = styled.div`
  .header-content-top {
    margin-top: 15px;
  }
  .header-content-top-content {
    border-radius: 8px;
    background: var(--Project-White, #fff);
    box-shadow: 2px 0px 24px 5px rgba(86, 59, 255, 0.04);

    width: auto;
    display: inline-flex;
    > div {
      width: 150px;
      text-align: center;
      padding: 12px;
    }
  }
  .pagination-view-sec {
    margin-top: 20px;
    text-align: center;
  }
  .data-full-table-view {
    tr th {
      width: 100px;
    }
  }
  .redclub-filters {
    margin-top: 20px;
    label {
      display: block;
    }
  }
  .report-box-row-all {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    .report-box-row {
      border-radius: 8px;
      background: #f2e8ff;
      padding: 16px;
      text-align: center;
      > div {
        margin-bottom: 20px;
      }
    }
    .text-red-top {
      color: #000;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }
    .count-red-top {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 125% */
    }
  }
  .main_header {
    color: var(--Shade-100, #000);

    /* Heading 06/H6/Small/Semibold */
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px; /* 135% */
  }

  .filter_btn_wrp {
    width: auto;
    max-width: fit-content;
    padding: 0px;
    button {
      background: #fff;
    }
  }

  .checkbox-button {
    display: flex;
    width: 130px;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0px;
    border-radius: 6px;
    border: 1px solid var(--Colour01-Black, #1f2028);
    color: var(--Colour01-Black, #1f2028);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.024px;
    text-transform: uppercase;
  }

  .checkbox-button.checked {
    background-color: var(--Primary-Red, #008080);
    color: white;
    border: 2px solid #008080;
  }

  .checkbox-button:focus {
    outline: none;
  }
  .filter_btn_wrp_row {
    justify-content: space-between;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .filter_lft_side {
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 15px;
    flex: 0 0 98%;
    max-width: 98%;
  }
  .custom_heading {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    margin-bottom: 20px;

    img {
      margin-left: 10px;
    }
  }
  .date_range_text {
    color: var(--Shade-100, #000);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .filter_right_side_11 {
    padding: 15px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    flex: 0 0 30.333333%;
    max-width: 30.333333%;
    .heading {
      color: var(--Shade-100, #000);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .agg_row {
      margin-left: 0px;
      margin-right: 0px;
      justify-content: space-around;
      button {
        width: 79px;
      }
    }
  }

  .report-box-row-all {
    .report-box-row {
      div {
        margin-bottom: 0px;
      }
    }
    .report-box-row-1 {
      background: #a6d9d9;
    }
    .report-box-row-2 {
      background:#e8ecec;
    }
    .report-box-row-3 {
      background: #e7ffdc;
    }
    .report-box-row-4 {
      background: #ffe2f7;
    }
    .report-box-row-5 {
      background: #e5e1ff;
    }
    .report-box-row-6 {
      background: #ffdaed;
    }
  }
  .redclub-dates-row {
    margin-left: 0px;
    margin-right: 0px;
    gap: 3%;
    padding-bottom: 30px;
    border-bottom: 1px solid #cfd3d7;
  }

  .pre_filter_text {
    margin-top: 30px;
    margin-bottom: 30px;
    .first_text {
      color: #000;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */

      span {
        color: #008080
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
    .second_text {
      color: var(--Shade-100, #000);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .last_text {
      color: #000;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }

  .text_chart_wrap {
    width: 98%;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding-left: 15px;
    padding-right: 15px;
  }

  .head_button_sec {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 25px;
    gap: 30px;
    padding-right: 20px;
    button {
      display: flex;
      width: 176px;
      padding: 14px 32px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      background: #008080;
      height: 52px;
      border: none;
      padding: 0px;
      color: #fff;
    }
  }

  .table_wrap {
    padding-left: 9px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden;
    margin-right: 15px;
    padding: 0px;
    .note_text {
      background: var(#008080, #e03339);
      color: #fff;
      height: 40px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 33px;
    }
  }
  .table_buttons {
    display: flex;
    justify-content: end;
    margin-right: 20px;
    gap: 20px;
    margin-bottom: 20px;
  }
  .clear_all_text {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
    p {
      margin-bottom: 0px;
      color: var(#008080, #008080);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.024px;
      text-transform: uppercase;
      cursor: pointer;
    }
    img {
      cursor: pointer;
    }
  }
  .chart_container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin-bottom: 25px;
    padding: 15px;
  }
`;
